import { Icon, Toast, Search, Swipe, SwipeItem, PullRefresh, List, Image, } from 'vant'

export default{
  name: 'KeepAlive',
  components:{
    'van-icon': Icon,
    'van-search': Search,
    'van-swipe': Swipe,
    'van-list': List,
    'van-image': Image,
    'van-swipe-item': SwipeItem,
    'van-pull-refresh': PullRefresh,
  },
  data() {
    return {
      apis:{//接口列表
        actives:'/content/slicedQueryContent',//特色活动列表
        queryBanner:'/banner/queryBanner',//banner图
      },
      refreshing:false,//是否刷新
      loading: true,//是否加载中
      finished: false,//数据是否已经加载完成
      scrollTop:'',
      params:{
        code:'lyhd',
        pageIndex:1,
        pageSize:10,
        sortName:'create_date',
        sortType:'desc'
      },
      actives:[],//活动列表
      bannerList:[]
    }
  },
  mounted() {
    const me = this;
    me.loadActives();
    me.bannerInit();
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      document.body.scrollTop = vm.scrollTop
    })
  },
  beforeRouteLeave (to, from, next){
    this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    next()
  },
  created(){

    this.getShareInfo(
      this.$global.shareTitle,
      this.$global.visitUrl,
      this.$global.shareImage,
      this.$global.shareIntro,
    );
  },
  methods: {
    bannerInit(){//轮播图接口
      const me = this;
      me.$get({
        url:me.apis.queryBanner,
        params:{
          position:10
        }
      }).then(rep=>{
        console.log(rep);
        me.bannerList = rep
      })
    },
    revert(){
      this.$router.go(-1)
    },
    loadActives(){//加载活动列表
      const me = this;
      me.$get({
        url: me.apis.actives,
        params: me.params
      }).then(rsp => {
        me.loading = false;
        if(me.refreshing){
          me.actives = [];
          me.refreshing = false;
        }
        var list = rsp.data;
        if(list && list.length > 0){
          me.actives = me.actives.concat(list);
        }
        if(!list || list.length < me.params.pageSize){
          me.finished = true;
        }
      });
    },
    typeChange(code){//类型变化时执行
      const me = this;
      me.refreshing = true;
      me.finished = false;
      me.params.code = code;
      me.params.pageIndex = 1;
      me.loadActives();
    },
    onRefresh () {//下拉刷新
      const me = this;
      me.finished = false;
      me.params.pageIndex = 1;
      me.loadActives();
    },
    onLoad(){//上拉加载
      const me = this;
      let page = me.params.pageIndex;
      page = page ? page + 1 : 1;
      me.params.pageIndex = page;
      me.loadActives();
    },
    toDetail(code){//跳转到详情
      this.$router.push('/activDetails/' + code);
    }
  },
}
